<template>
  <li
    class="reaction-user"
    ref="list"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
    :class="{ 'reaction-user_tool': isShowTooltip }"
  >
    <router-link
      :to="{ name: 'UserInfo', params: { user_id: user.id } }"
      :target="'_blank'"
      class="reaction-user__link"
    >
      <Avatar :user="user" :show-info="showInfo" :showRole="false" />
    </router-link>
  </li>
</template>

<script>
import Avatar from "@/modules/Messenger/components/ChatItem/Avatar";
import { mapActions } from "vuex";
export default {
  name: "ReactionUser",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    isShowTooltip: {
      type: Boolean,
      default: false
    }
  },
  components: { Avatar },
  data() {
    return {
      showTimer: null,
      hideTimer: null
    };
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["showChatTooltip"]),
    showTooltip() {
      if (this.isShowTooltip) {
        clearTimeout(this.showTimer);
        this.showTimer = setTimeout(() => {
          const rect = this.$refs.list.getBoundingClientRect();
          const container = document.querySelector(".attached-modal");
          this.showChatTooltip({
            textTooltip: this.user.full_name,
            show: true,
            container,
            x: rect.x,
            y: rect.y - 30
          });
        }, 200);
      }
    },
    hideTooltip() {
      if (this.isShowTooltip) {
        clearTimeout(this.hideTimer);
        this.hideTimer = setTimeout(() => {
          this.showChatTooltip({
            textTooltip: "Показать список всех реакций",
            show: false,
            container: null,
            x: 0,
            y: 0
          });
        }, 200);
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.reaction-user {
  margin-bottom: 8px;
  &_tool {
    margin-right: -8px;
    margin-bottom: 0;
    /deep/ {
      img {
        width: 40px;
        height: 40px;
        margin-right: 0;
      }
    }
  }
}
</style>
