<template>
  <div class="reactions">
    <div class="reactions__wrap">
      <h4 class="reactions__title">Участники</h4>
      <h4 class="reactions__title">Реакции</h4>
    </div>
    <ul class="reactions__list">
      <ReactionItem :reaction="item" v-for="item in reactionList" :key="item.reaction" />
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReactionItem from "@/modules/Messenger/components/ChatModal/AttachedModal/Reaction/ReactionItem";

export default {
  name: "ReactionListContent",
  mixins: [],
  props: {
    files: {
      type: Array
    }
  },
  components: { ReactionItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("MessengerModule", ["reactionList"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

::-webkit-scrollbar {
  border-radius: 10px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: $bg-gray-400;
  border-radius: 10px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: $surface-disabled;
}
.reactions {
  width: 100%;
  padding: 24px;
  max-height: calc(100% - 128px);
  overflow-y: auto;
  @media screen and (max-width: $xs) {
    max-height: calc(100% - 58px);
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  &__title {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: $text-subdued;
  }
  &__list {
    all: unset;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
  }
  /deep/ {
    .reaction-item {
      &__emoji {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 1px solid #eae8f0;
        margin-right: 0;
      }
    }
  }
}
</style>
