<template>
  <li class="reaction-item" :id="reaction.reaction">
    <div class="reaction-item__wrap">
      <ul class="reaction-item__users">
        <ReactionUser
          :isShowTooltip="true"
          :user="user.userInfo"
          v-for="user in setUserList"
          :key="user.id"
        />
        <li class="reaction-item__count" @click="showUserList" v-if="isLongUserList">
          +{{ reaction.users.length - 3 }}
        </li>
      </ul>
      <p class="reaction-item__emoji">
        {{ reaction.reaction }}
      </p>
    </div>
    <ul class="reaction-item__full-info" v-if="showList">
      <ReactionUser
        :showInfo="true"
        :user="user.userInfo"
        v-for="user in reaction.users"
        :key="user.id"
      />
    </ul>
  </li>
</template>

<script>
import ReactionUser from "@/modules/Messenger/components/ChatModal/AttachedModal/Reaction/ReactionUser";
import elementInViewport from "@/modules/Messenger/mixins/elementInViewport.mixin";
import { mapActions, mapState } from "vuex";
export default {
  name: "ReactionItem",
  mixins: [elementInViewport],
  props: {
    reaction: {
      type: Object,
      default: () => {}
    }
  },
  components: { ReactionUser },
  data() {
    return {
      showList: false
    };
  },
  mounted() {
    this.setDefault(".reactions", ".reactions__list");
    this.el = document.querySelector(`#${this.reaction.reaction}`);
    this.observer.observe(this.el);
  },
  methods: {
    ...mapActions("MessengerModule", ["getUserReactionWithScroll"]),
    handleView(direction) {
      const length = this.reactionList.length;
      const index = this.reactionList.findIndex((item) => item.reaction === this.reaction.reaction);
      if (
        length - 5 === index &&
        this.attachedCount > 20 &&
        this.isVisible &&
        this.attachedCount > length
      ) {
        const page = Math.ceil(length / 20) + 1;
        this.getUserReactionWithScroll({ page, id: this.reactionId });
      }
    },
    showUserList() {
      this.showList = !this.showList;
    }
  },
  computed: {
    ...mapState("MessengerModule", ["reactionList", "attachedCount", "reactionId"]),
    isLongUserList() {
      return this.reaction.users.length > 3;
    },
    setUserList() {
      const list = this.reaction.users.map((item) => item);
      if (this.isLongUserList) {
        return list.slice(0, 3);
      } else {
        return list;
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.reaction-item {
  padding: 8px;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__emoji {
    margin-right: 8px;
  }
  &__users {
    all: unset;
    list-style: none;
    display: flex;
    align-items: center;
  }
  &__full-info {
    all: unset;
    list-style: none;
    display: flex;
    flex-flow: column;
    margin-top: 8px;
  }
  &__count {
    margin-left: 16px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: $text-subdued;
    cursor: pointer;
    user-select: none;
  }
}
</style>
